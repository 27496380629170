import React from "react"

import DefaultLayout from "../layouts/default"
import TwoThirdsGrid from "../components/layout/TwoThirdsGrid"
import Text from "../components/text/Text"
import HeadlineWithSub from "../components/text/HeadlineWithSub"
import DottedLineHorizontal from "../components/DottedLineHorizontal"

const active = {
  title: "Coaching"
}

const Coaching = () =>
<DefaultLayout image="/assets/images/IMG_7048.PNG" active={active}>
  <TwoThirdsGrid textRight>

    <div>
      <HeadlineWithSub>
        <h1>Coaching</h1>
        <h4>
          Vertrauen, Handlungsraum, Klarheit, Entwicklung
        </h4>
      </HeadlineWithSub>
    </div>
    <Text>
      <p><a href="#Stresscoaching"><strong>Stresscoaching und Burnoutprävention</strong></a> in herausfordernden beruflichen
        Situationen – online und im Freien</p>
      <p><a href="#StresscoachingFurEltern"><strong>Stresscoaching und Burnoutprävention für Eltern</strong></a> mit herausforderndem
        Familienalltag – online und im Freien</p>
      <p><a href="#Coaching"><strong>Coaching</strong></a> in herausfordernden Situationen, persönlichen Krisen oder zur
      beruflichen Neuorientierung und zur Standortbestimmung, um Klarheit zu
        erlangen – online, inhouse oder bei einem Spaziergang - insbesondere für Frauen ab 40</p>
      <p><a href="#Kurzzeitcoaching"><strong>Kurzzeitcoaching</strong></a> in spezifischen Momenten, um in Fragen Klärung oder in
      anstehenden Entscheiden Orientierung zu finden – das auf einem ausgedehnten
        Spaziergang. Gerne begleite ich sie in ihrem Weiterkommen.</p>

      <div id="Stresscoaching" style={{marginBottom: 100}}></div>

      <div>
        <h3>Stresscoaching und Burnoutprävention</h3>
        <p>Stresscoaching und Burnoutprävention in herausfordernden beruflichen und
        privaten Situationen – online und im Freien</p>
        <p>Im Stresscoaching wird in einer Bestandesaufnahme der Blick auf die
        Anforderungen von aussen gerichtet und die individuelle Haltung des Coachees
        unter die Lupe genommen. Welche Stressoren aus dem beruflichen oder privaten
        Bereich sind wirksam und welche Glaubenssätze sind aktiv? In Gesprächen
        (online) begleite ich den oder die Coachee in der Entwicklung von
        Veränderungsmöglichkeiten. Das, was sich im Realitätscheck bewährt, wird in den
        Alltag integriert. Zeit- und Selbstmanagementtechniken werden aktiviert bzw.
        weiterentwickelt. Interessen, Stärken und Ressourcen werden identifiziert, denn
        Veränderung braucht Energie!</p>
        <p>Ein wichtiger Teil im Stresscoaching bildet die Entwicklung einer
        Entspannungskompetenz, die mit Methoden der Sinneswahrnehmung, der
        Achtsamkeit, der Gestaltung und mit Unterstützung des Naturraumes gestärkt
        wird. Zu spezifischen Zeitpunkten im Prozess treffen wir uns im Freien, denn die
        Natur hat nachweislich einen positiven Einfluss auf den Körper, das Denken und
        auf die Psyche.</p>
        <p>Stress ist Teil des Lebens – physiologisch ist es für unser Überleben notwendig, Stress
        empfinden zu können. Wenn der Stress jedoch aufrechterhalten wird, hat das Folgen
        für die Gesundheit, die Lebensqualität und somit für die Arbeitsfähigkeit. Aus dem JobStress-Index 2022 geht hervor, dass sich 30,3 % der erwerbstätigen Menschen in der
        Schweiz emotional erschöpft fühlen. Der Anteil der Erwerbstätigen, deren der JobStress-Index sich im kritischen Bereich befindet, beträgt 28,2 %, das heisst, dass diese
        Menschen über deutlich mehr Belastung klagen, als sie mit ihren Ressourcen
        ausgleichen können. Der arbeitsbezogene Stress kostet die Wirtschaft etwa 6,5 Mia.
        Franken. Viele Menschen fühlen sich durch die Arbeit oder private Situationen
        erschöpft und haben Schwierigkeiten abzuschalten (<a href="https://gesundheitsfoerderung.ch/sites/default/files/migration/documents/Faktenbl
        att_072_GFCH_2022-08_-_Job-Stress-Index_2022.pdf" target='_blank' rel="noreferrer">Quelle Job-Stress-Index</a>)</p>
        <p>Für jene, die gerne mit fixen Zahlen rechnen, habe ich je ein Package für drei bzw.
        sechs Monate Stresscoaching zusammengestellt. Darin enthalten sind
        regelmässige Coachingstunden online, ein bzw. zwei Treffen im Freien, ein
        Abschlussgespräch und der individuelle Support zwischen den Terminen per Email
        oder Telefon. Um mehr über die Einzelheiten zu erfahren, nehmen sie unverbindlich
        mit mir Kontakt auf.</p>
        <p>Bei einem unverbindlichen Vorgespräch nehme ich gerne ihre Anliegen auf.</p>
        <p id="StresscoachingFurEltern">Für Fragen und individuell zugeschnittene Angebote: Fragen sie mich an! <br/>
          <a href="mailto:info@strich.sg">info@strich.sg</a> oder <a href="tel:079 844 04 07">079 844 04 07</a>
        </p>
      </div>

      <div>
        <h3>Stresscoaching und Burnoutprävention für Eltern</h3>
        <p>Stresscoaching und Burnoutprävention für Eltern mit herausforderndem
        Familienalltag – online und im Freien</p>
        <p>Durch jahrelange Erfahrung in der Arbeit mit Kindern mit und ohne spezielle
        Bedürfnisse und der Beratung von Familien begleite ich Mütter und Väter, die
        zeitlich und emotional stark belastet sind, so dass manchmal die schönen
        Momente des Familienlebens aufgefressen werden und sich
        Handlungsunfähigkeit, Versagens- und Schuldgefühle breit machen.</p>
        <p>Durch die Elternschaft werden Belastungen wie wenig Schlaf, ständige
        Verfügbarkeit, wenig Regenerationszeit und Doppelbelastung durch Familie und
        Erwerbsarbeit präsent. Fehlt der Familie ein soziales Umfeld, durch das sie
        Entlastung erfahren können, führt das zu einem hohen Stressniveau und kann in einem Burnout münden. Das über lange Zeit hinweg hohe Stresserleben hat Auswirkungen auf die mentale und physische Gesundheit der Eltern.</p>
        <p>Im Stresscoaching wird in einer Bestandesaufnahme der Blick auf die
        Anforderungen von aussen gerichtet und die individuelle Haltung des Elternteiles
        angeschaut. Welche Stressoren aus dem Familienalltag und anderen Bereichen
        sind wirksam und welche Glaubenssätze sind aktiv? In Gesprächen (online) wird
        der Vater und/oder die Mutter begleitet, Veränderungsmöglichkeiten zu entwickeln
        und diese in einem Realitätscheck zu überprüfen. Das, was sich bewährt, wird in
        den Alltag integriert. Zeit- und Selbstmanagementtechniken werden aktiviert bzw.
        weiterentwickelt. Interessen, Stärken und Ressourcen werden identifiziert, denn
        Veränderung braucht Energie!</p>
        <p>Ein wichtiger Teil im Stresscoaching bildet die Entwicklung einer
        Entspannungskompetenz, die mit Methoden der Sinneswahrnehmung, der
        Achtsamkeit, der Gestaltung und mit Unterstützung des Naturraumes gestärkt
        wird. An spezifischen Punkten des Prozesses treffen wir uns im Freien, denn die
        Natur hat nachweislich einen positiven Einfluss auf den Körper, das Denken und
        auf die Psyche.</p>
        <p>Ich biete Begegnung in schwierigen Phasen des Familienlebens und eine
        ganzheitliche Begleitung in Veränderungsprozessen an. Gemeinsam formulieren
        wir Ziele, analysieren Wahrnehmungen, Denkweisen und Handlungen, aktivieren
        Ressourcen bzw. Stärken und entwickeln neue Visionen, Ideen und
        Lösungsansätze, die sie schrittweise umsetzen.</p>
        <p>Für die, die gerne mit fixen Zahlen rechnen, habe ich je ein Package für drei bzw.
        sechs Monate Stresscoaching zusammengestellt. Darin enthalten sind
        regelmässige Coachingstunden online, ein bzw. zwei Treffen im Freien, ein
        Abschlussgespräch und der individuelle Support zwischen den Terminen per Email
        oder Telefon. Um mehr über die Einzelheiten zu erfahren, nehmen sie unverbindlich
        mit mir Kontakt auf.</p>
        <p>Bei einem unverbindlichen Vorgespräch nehme ich gerne ihre Anliegen auf.</p>
        <p id='Coaching'>Für Fragen und individuell zugeschnittene Angebote: Fragen sie mich an! <br/>
          <a href="mailto:info@strich.sg">info@strich.sg</a> oder <a href="tel:079 844 04 07">079 844 04 07</a></p>
      </div>

      <div>
        <h3>Coaching</h3>
        <p>Coaching in herausfordernden Situationen, persönlichen Krisen oder zur
        beruflichen Neuorientierung und zur Standortbestimmung, um Klarheit zu
        erlangen – online, inhouse oder bei einem Coachingspaziergang. Das Angebot eignet sich insbesondere für Frauen ab 40 für eine Standortbestimmung und Neuausrichtung.</p>
        <p>Mit meiner langjährigen Erfahrung biete ich Begegnung in belastenden
        Lebensphasen und Begleitung in Veränderungsprozessen an. Gemeinsam
        formulieren wir Ziele, analysieren Wahrnehmungen, Denkweisen und Handlungen,
        aktivieren Ressourcen bzw. Stärken und entwickeln neue Visionen, Ideen und
        Lösungsansätze, die sie schrittweise umsetzen.</p>
        <p>Bei einem unverbindlichen Vorgespräch nehme ich gerne ihre Anliegen auf.</p>
        <p id="Kurzzeitcoaching">Für Fragen und individuell zugeschnittene Angebote kontaktieren sie mich! <br/>
          <a href="mailto:info@strich.sg">info@strich.sg</a> oder <a href="tel:079 844 04 07">079 844 04 07</a>
        </p>
      </div>

      <div>
        <h3>Kurzzeitcoaching</h3>
        <p>Kurzzeitcoaching in spezifischen Momenten, um in Fragen Klärung oder in
        anstehenden Entscheidungen Orientierung zu finden – das auf einem ausgedehnten
        Spaziergang. Gerne begleite ich sie in ihrem Weiterkommen.</p>
        <p>Bei einem unverbindlichen Vorgespräch nehme ich gerne ihre Anliegen auf.</p>
        <p>Für Fragen oder individuell zugeschnittene Angebote kontaktieren sie mich! <br/>
          <a href="mailto:info@strich.sg">info@strich.sg</a> oder <a href="tel:079 844 04 07">079 844 04 07</a>
        </p>
      </div>

      <DottedLineHorizontal />
      <p>
        <strong>Durchführungsort</strong><br />
        Nach Absprache
      </p>
    </Text>
  </TwoThirdsGrid>
</DefaultLayout>

export default Coaching